import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useCreateRoomMutation } from "../../store/services/roomsApi";
import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";

import { AiOutlineClose } from "react-icons/ai";

import Modal from "../common/Modal";

const initialValues = {
  name: "",
  area: "",
  height: "",
};

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Name is required"),
  area: Yup.number().required("Area Is Required"),
  height: Yup.number().required("Height Is Required"),
});

const AddRoom = () => {
  const navigate = useNavigate();
  const [createRoom, { data: roomData, error: roomError, isError: isRoomError, isSuccess: isRoomSuccess }] = useCreateRoomMutation();
  const toast = useToast();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      createRoom(values);
      action.resetForm();
    },
  });

  useEffect(() => {
    if (isRoomSuccess) {
      toast({
        title: "Room Added Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/rooms/${roomData._id}`);
    }
  }, [isRoomSuccess, roomError]);

  useEffect(() => {
    if (isRoomError) {
      console.log(roomError);
      toast({
        title: `Something went wrong. Please Try Again`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isRoomError, roomError]);

  return (
    <Modal>
      <h1 className="font-bold text-2xl">Add Room</h1>

      <form onSubmit={handleSubmit} className="mt-5" action="">
        <div className="inpGrp text-left relative">
          <label className=" font-semibold">Name Of The Room</label>
          <input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder=""
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
        </div>
        <div className="inpGrp text-left mt-4 relative">
          <label className=" font-semibold">Area</label>
          <input
            name="area"
            id="area"
            value={values.area}
            onChange={handleChange}
            type="Number"
            placeholder=""
            className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
          />
          <div className="span text-xs font-bold bottom-2 absolute right-3 text-[#9D9D9D]">Sq. Ft.</div>
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.area && touched.area ? errors.area : ""}</span>
        </div>
        <div className="inpGrp text-left mt-4 relative">
          <label className=" font-semibold">Height</label>
          <input
            name="height"
            id="height"
            value={values.height}
            onChange={handleChange}
            type="Number"
            placeholder=""
            className="bg-[#F5F5F5] font-semibold focus:ring-0 focus:outline-black px-2 py-2 rounded-md w-full relative"
          />
          <div className="span text-xs font-bold bottom-2 absolute right-3 text-[#9D9D9D]">In. Ft.</div>
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.height && touched.height ? errors.height : ""}</span>
        </div>

        <button type="submit" className="bg-black text-white w-3/5 h-8 mt-6 rounded-md">
          Add Room
        </button>
      </form>
    </Modal>
  );
};

export default AddRoom;
