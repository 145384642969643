import { useListRoomsMutation } from "../../store/services/roomsApi";
import { IoIosArrowForward } from "react-icons/io";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SkeletonText } from "@chakra-ui/react";

const RoomLists = () => {
  const [list, { data: roomData, error: roomError, isError: isRoomError, isSuccess: isRoomSuccess, isLoading: isLoading }] = useListRoomsMutation();

  const navigate = useNavigate();

  const [roomsList, setRoomList] = useState([]);
  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (isRoomSuccess) {
      console.log(roomData);
      setRoomList(roomData);
      console.log("room list updated-> ", roomsList);
    }
  }, [roomData, isRoomSuccess]);

  return (
    <>
      {isLoading && (
        <div className="w-11/12  flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      {roomsList &&
        roomsList.map((data) => {
          return (
            <div
              key={data._id}
              onClick={() => navigate(`/dashboard/rooms/${data._id}`)}
              className="container w-11/12 px-3 py-2 m-auto mt-3 bg-[#F9F9F9] h-20 rounded-2xl border border-opacity-10"
            >
              <h4 className="font-semibold text-left">{data.name}</h4>
              <p className=" text-slate-500 text-left text-xs">
                {data.area}sq. ft. x {data.height}ft.
              </p>
              <h5 className="w-full  flex justify-end">
                <IoIosArrowForward className="text-xl" />
              </h5>
            </div>
          );
        })}
      <button
        onClick={() => navigate("/dashboard/addRoom")}
        className="bg-black fixed bottom-12 right-2 w-2/5 h-8 mt-10 text-lg mb-5 rounded-md text-white "
      >
        + Add Room
      </button>
    </>
  );
};
export default RoomLists;
