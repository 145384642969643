import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { SkeletonText } from "@chakra-ui/react";

const Modal = (props) => {
  const { isLoading } = props.isLoading ? props.isLoading : false;
  const navigate = useNavigate();
  console.log(isLoading, props.isLoading, "asdsaoda isloading");

  return (
    <div className="w-screen h-screen bg-black backdrop-blur-[2px] bg-opacity-80  absolute top-0">
      <div className="modal bg-white rounded-2xl absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 w-4/5 ">
        <div className="relative w-full  h-full py-10 px-5">
          <span onClick={() => navigate(-1)} className="absolute top-3 right-4 text-2xl">
            {" "}
            {props.isLoading && (
              <div className="w-11/12   flex flex-col gap-5 m-auto">
                {/* <SkeletonCircle /> */}
                <SkeletonText speed={2} startColor="#ededed" />
              </div>
            )}
            <AiOutlineClose />{" "}
          </span>
          {props.children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
