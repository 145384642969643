import { useEffect, useState } from "react";
import praanLogo from "../../assets/images/praan.svg";

import Topnav from "../../components/dashboard/Topnav";
import RoomLists from "../../components/dashboard/RoomLists";
import RoomInfo from "../../components/dashboard/RoomInfo";
import DeviceList from "../../components/dashboard/DeviceList";
import DeviceInfo from "../../components/dashboard/DeviceInfo";

import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useHasRoomMutation } from "../../store/services/usersApi";

const Dashboard = () => {
  const [firstLogin, setFirstLogin] = useState(false);

  const [hasRoom, { data: roomData, isSuccess: roomSuccess, error: roomError, isError: isRoomError }] = useHasRoomMutation();

  const navigate = useNavigate();

  useEffect(() => {
    hasRoom();
  }, []);

  useEffect(() => {
    console.log("room data", roomData);
    if (!roomData && roomSuccess) setFirstLogin(true);
  }, [roomData, roomSuccess]);

  return (
    <div className="h-full w-screen">
      {firstLogin && (
        <div className="pt-10 flex h-full flex-col items-center justify-between w-4/5 m-auto">
          <div className="flex flex-col items-center ">
            <img className="w-1/3" src={praanLogo} alt="praan logo" />
            <br />
            <h1 className="font-semibold text-xl">Welcome To Praan</h1>
            <br />
            <p className="text-slate-600 font-semibold">Please Create A Room and add your device to get started.</p>
          </div>
          <button onClick={() => navigate("/dashboard/addRoom")} className="bg-black w-3/5 h-8 mt-10 text-lg mb-5 rounded-md text-white ">
            + Add Room
          </button>
        </div>
      )}
      {!firstLogin && (
        <div className="">
          <Topnav />
          <div className="w-full h-full mt-10">
            {/* <RoomLists /> */}
            <Routes>
              <Route path="/rooms" element={<RoomLists />} />
              <Route path="/rooms/:id" element={<RoomInfo />} />
              <Route path="/devices" element={<DeviceList />} />
              <Route path="/devices/:id" element={<DeviceInfo />} />
              <Route path="/*" element={<RoomLists />} />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
