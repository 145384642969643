import "./App.css";
import { BrowserView, MobileView } from "react-device-detect";
import { useAppSelector } from "./store/hooks";
import { Route, Routes } from "react-router-dom";

import DesktopPage from "./pages/desktopPage/DesktopPage";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import LoginPage from "./pages/login/Login";
import SignupPage from "./pages/signup/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import AddRoom from "./components/dashboard/AddRooms";
import AddDevice from "./components/dashboard/AddDevice";
import Settings from "./pages/setting/Settings";
import DeviceControl from "./components/settings/DeviceControl";
import EditDevice from "./components/settings/EditDevice";
import EditRooms from "./components/settings/EditRooms";
import Support from "./pages/support/Support";
import Offline from "./pages/offline/Offline";
import { useEffect, useState } from "react";

function App() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="App select-none ">
      <BrowserView>
        <DesktopPage />
      </BrowserView>
      <MobileView>
        <Routes>
          <Route path="/offline" element={<Offline />} />
        </Routes>
        <div className="container">
          {!isAuthenticated && (
            <div className="h-screen">
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<LoginPage />} />
                <Route path="/*" element={<LoginPage />} />
              </Routes>
            </div>
          )}
          {isAuthenticated && (
            <div className=" pt-16 mb-16 overflow-scroll">
              <NavBar />
              <Header />
              <Routes>
                <Route path="/dashboard/addRoom" element={<AddRoom />} />
                <Route path="/dashboard/addDevice/:roomID" element={<AddDevice />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/support/*" element={<Support />} />
                <Route path="/settings/deviceControls/:deviceId" element={<DeviceControl />} />
                <Route path="/settings/editDevice/:deviceName/:deviceId" element={<EditDevice />} />
                <Route path="/settings/editRooms/:roomId" element={<EditRooms />} />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/*" element={<Dashboard />} />
              </Routes>
            </div>
          )}
        </div>
      </MobileView>
    </div>
  );
}

export default App;
