import { useEffect } from "react";
import { useFindRoomByIDQuery } from "../../store/services/roomsApi";
import { useParams } from "react-router-dom";

import roomImage from "../../assets/images/rooms.svg";

import { useNavigate } from "react-router-dom";

const RoomInfo = () => {
  const { id } = useParams();
  const {
    data: roomData,
    isSuccess: roomSuccess,
    error: roomError,
    isError: isRoomError,
  } = useFindRoomByIDQuery(id, { pollingInterval: 2 * 60 * 1000, refetchOnMountOrArgChange: true }); //calling every 2 mins
  const navigate = useNavigate();

  // useEffect(() => {
  //   findRoom(id);
  // }, []);

  return (
    <div className="h-full">
      {roomData && (
        <div className="h-full">
          <div className="top">
            <h1>
              AQI : <span className="font-bold"> {roomData?.AQI}</span>
            </h1>
            <h1 className="font-bold text-xl">{roomData.name}</h1>
            <p className="text-xs text-slate-500 mt-1">
              No. Of Devices : <span className="font-bold text-black"> {roomData.numberOfDevices} </span>
            </p>
          </div>

          <div className="image m-auto">
            <img className="m-auto w-2/5 mt-5" src={roomImage} alt="Room Illustration" />
          </div>

          {roomData.sensorData && roomData.numberOfDevices > 0 && (
            <div className="info m-auto mt-5  w-4/5 grid grid-cols-2  gap-6">
              <div className="val">
                <h2 className="values font-bold">{(+roomData?.sensorData.co2).valueOf()} µg/m³</h2>
                <p>CO2</p>
              </div>
              <div className="val">
                <h2 className="values font-bold">{(+roomData?.sensorData.humidity).valueOf()}%</h2>
                <p>Humidity</p>
              </div>
              <div className="val">
                <h2 className="values font-bold">{(+roomData?.sensorData.p1).valueOf()} µg/m³</h2>
                <p>PM 1.0</p>
              </div>
              <div className="val">
                <h2 className="values font-bold"> {(+roomData?.sensorData.p10).valueOf()} µg/m³</h2>
                <p>PM 10.0</p>
              </div>
              <div className="val">
                <h2 className="values font-bold"> {(+roomData?.sensorData.p25).valueOf()} µg/m³</h2>
                <p>PM 2.5</p>
              </div>
              <div className="val">
                <h2 className="values font-bold"> {(+roomData?.sensorData.sound).valueOf()} db</h2>
                <p>Noise</p>
              </div>
              <div className="val">
                <h2 className="values font-bold"> {(+roomData?.sensorData.temperature).valueOf()}°C</h2>
                <p>Temperature</p>
              </div>
              <div className="val">
                <h2 className="values font-bold"> {(+roomData?.sensorData.voc).valueOf()} ppb</h2>
                <p>TVOC</p>
              </div>
              <button
                onClick={() => navigate(`/dashboard/addDevice/${id}`)}
                className="bg-black fixed bottom-12 right-2 w-2/5 h-8 mt-10 text-lg mb-5 rounded-md text-white "
              >
                Add Device
              </button>
            </div>
          )}
          {roomData.numberOfDevices == 0 && (
            <div className="info   w-4/5 m-auto">
              <div className="mt-5">
                No Devices Found In the room. Please add device to continue
                <br /> <br />
                <button onClick={() => navigate(`/dashboard/addDevice/${roomData._id}`)} className="bg-black w-3/5 h-7 rounded-md text-white">
                  Add Device
                </button>
              </div>
            </div>
          )}
          {!roomData.sensorData && (
            <div className="info   w-4/5 mt-10 m-auto">{"Please wait few minutes for the data to come in from the device."}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default RoomInfo;
