import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import hiveImg from "../../assets/images/hiveImage.png";
import { useDeviceListMutation } from "../../store/services/deviceApi";

import NetworkStrength from "../../components/common/NetworkStrength";
import { SkeletonText } from "@chakra-ui/react";

const Support = () => {
  const [deviceList, { data: deviceData, isLoading: isLoading }] = useDeviceListMutation();
  // const navigate = useNavigate();

  useEffect(() => {
    deviceList();
  }, []);

  return (
    <div className="text-left w-11/12 m-auto">
      <h1 className="font-bold text-2xl mt-3 mb-5 ml-1 ">Support</h1>
      {isLoading && (
        <div className="  flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      <Accordion borderRadius={"3xl"} allowMultiple>
        {deviceData &&
          deviceData.map((device) => {
            return (
              <AccordionItem
                minHeight={"14"}
                borderRadius={"xl"}
                bgColor={"#F9F9F9"}
                border={"1px"}
                marginTop={"3"}
                borderColor={"rgba(0, 0, 0, 0.1)"}
                _hover={{ bgColor: "#F9F9F9" }}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton position={"relative"} borderRadius={"xl"} _hover={{ bgColor: "#F9F9F9" }}>
                        <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                          <div className="flex items-center">
                            {device.name} <p className="text-slate-500 text-xs font-light ml-1"> | {device.roomObject.name} </p>
                          </div>
                          <img className="w-1/2 mt-1" src={hiveImg} alt="" />
                        </Box>
                        <div className="network absolute h-4/5 items-center flex flex-col justify-between  top-2 right-2">
                          <NetworkStrength signalStrength={device.networkStrength} signalType={device.networkType} />{" "}
                          <AccordionIcon fontSize={"2xl"} />
                        </div>
                      </AccordionButton>
                    </h2>

                    <AccordionPanel pb={"8"} bgColor={"#F9F9F9"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                      <div className="h-16 relative mt-1">
                        <div className="name w-full items-center">
                          <div className="flex items-center">
                            <p className="font-semibold text-[13px] mr-1">HEPA Filter:</p>{" "}
                            <p className="text-[13px]">{device.deviceFilterStatus.hepaDaysRemaining} Days </p>
                          </div>
                          <div className="flex items-center">
                            <p className="font-semibold text-[13px] mr-1">ACF Filter:</p>{" "}
                            <p className="text-[13px]">{device.deviceFilterStatus.acfDaysRemaining} Days </p>
                          </div>
                        </div>
                        <div className="maintainenceBtns  flex justify-between items-center mt-3 text-[14px]">
                          <div className="underline  underline-offset-2">Maintainence History</div>
                          <button className="bg-black text-white rounded-md p-1 px-2">Schedule Maintainence</button>
                        </div>
                      </div>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
};
export default Support;
