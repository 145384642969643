import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    createDevice: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/${bodyObject.room}`,
          method: "post",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deviceList: builder.mutation({
      query: () => {
        return {
          url: `/device`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    editDevice: builder.mutation({
      query: (deviceObject) => {
        return {
          url: `/device/${deviceObject.device}`,
          method: "put",
          body: deviceObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deviceControl: builder.mutation({
      query: (deviceObject) => {
        return {
          url: `/device/ctd/${deviceObject.device}`,
          method: "post",
          body: deviceObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    findDeviceById: builder.query({
      query: (deviceId) => {
        return {
          url: `/device/${deviceId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const { useDeviceListMutation, useFindDeviceByIdQuery, useCreateDeviceMutation, useEditDeviceMutation, useDeviceControlMutation } = deviceApi;
