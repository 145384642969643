import { useEffect } from "react";
import { useDeviceListMutation } from "../../store/services/deviceApi";
import { useNavigate } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import NetworkStrength from "../common/NetworkStrength";
import { AiOutlinePoweroff } from "react-icons/ai";
import { SkeletonText } from "@chakra-ui/react";

const DeviceList = () => {
  const [list, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess, isLoading: isLoading }] =
    useDeviceListMutation();

  const navigate = useNavigate();

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="">
      {isLoading && (
        <div className="w-11/12   flex flex-col gap-5 m-auto">
          {/* <SkeletonCircle /> */}
          <SkeletonText speed={2} startColor="#ededed" />
        </div>
      )}
      {deviceData && (
        <div className="">
          {deviceData.length == 0 && <h1 className="w-4/5 m-auto">Please Add Device To Your Room To Continue</h1>}
          {deviceData.map((device) => {
            return (
              <div
                key={device._id}
                onClick={() => navigate(`/dashboard/devices/${device._id}`)}
                className="container relative mt-3 w-11/12 px-3 py-2 m-auto bg-[#F9F9F9] h-20 rounded-2xl border border-opacity-10"
              >
                <div className="topRow flex justify-between">
                  <h4 className="font-semibold text-left">{device.name}</h4>
                  <p className="text-lg  flex items-center">
                    <div className={`power mr-3 ${device.powerStatus ? "text-green-500" : "text-red-500"}`}>
                      <AiOutlinePoweroff />
                    </div>
                    <NetworkStrength signalStrength={device.networkStrength} signalType={device.networkType} />{" "}
                  </p>
                </div>
                <p className=" text-slate-500 text-left text-xs">{device.deviceID}</p>
                <p className=" text-slate-500 text-left text-xs">{device.roomObject.name}</p>
                <h5 className=" absolute  right-3 bottom-2">
                  <IoIosArrowForward className="text-xl" />
                </h5>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DeviceList;
