//React/Next/ThirdPart/Custom
import { useEffect } from "react";

import { useFormik } from "formik";
import { useSignupMutation } from "../../store/services/authApi";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import * as Yup from 'yup';

import praanLogo from "../../assets/images/praan.svg"

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short!')
    .max(50, 'Name is too long!')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});


const initialValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: ""
};
const SignupPage = () => {
  //importing api mutation to call api and access data
  const [signup, { data: signupData, error: signupError, isError: isSignupError, isSuccess: isSignupSuccess }] = useSignupMutation();


  const toast = useToast();
  const navigate = useNavigate();

  //using formik to manage form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values, action) => {
      signup(values);
      action.resetForm();
    },
  });

  //displaying toast on successfull login and saving the userdata along with the token
  useEffect(() => {
    if (isSignupSuccess) {
      toast({
        title: "Success",
        status: "success",
        isClosable: true,
      });

      navigate("/login");
    }
  }, [isSignupSuccess, signupError]);

  //displaying a toast if an error occurs
  useEffect(() => {
    if (isSignupError) {
      console.log(signupError);
      toast({
        title: `Something went wrong, Please try again.`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isSignupError, signupError]);

  return (
    <main className="h-full  flex p-10">
      <section className="flex-1 flex items-center justify-center text-black ">
        <div className="w-full">
        <p className="w-full flex justify-center ">
                <img src={praanLogo} alt="praan logo" className="w-6/12 align-middle " />
            </p>
          <form onSubmit={handleSubmit} className="flex flex-col text-black ">
            <h1 className="font-medium text-[#323232] text-2xl text-center ">Create Account</h1>
            <br />
            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md focus:outline-cyan-500">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="name"
                name="name"
                id="name"
                placeholder="Full Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.name && touched.name ? <p className="text-red-600 text-left">{errors.name}</p> : null}
            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md focus:outline-cyan-500">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="email"
                name="email"
                id="email"
                placeholder="abc@company.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? <p className="text-red-600 text-left">{errors.email}</p> : null}

            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md focus:outline-cyan-500">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
              {errors.password && touched.password ? <p className="text-red-600 text-left">{errors.password}</p> : null}
        
            <div className="flex items-center w-full bg-[#EDEDED] my-2 px-4 py-2 rounded-md focus:outline-cyan-500">
              <input
                className="bg-[#EDEDED] w-full focus:outline-none text-slate-700"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
              {errors.confirmPassword && touched.confirmPassword ? <p className="text-red-600 text-left">{errors.confirmPassword}</p> : null}
            <br />
            <div onClick={()=>{navigate("/login");}} className="forget cursor-pointer underline text-[#41464E] font-light underline-offset-2">Already have an account? Sign In</div>
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className="text-white bg-[#323232] hover:bg-blue-800 focus:ring-4 focus:outline-none w-4/5 h-8 font-medium rounded-lg text-sm text-center mr-3 md:mr-0"
              >
                Create Account
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default SignupPage;
