import { useEffect } from "react";
import { useFindDeviceByIdQuery, useDeviceControlMutation } from "../../store/services/deviceApi";
import { useParams } from "react-router-dom";
import hepaImage from "../../assets/images/hepaFilter.png";

import hiveImage from "../../assets/images/hiveImage.png";
import NetworkStrength from "../common/NetworkStrength";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useToast } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
const DeviceInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [sendPacket, { data: controlData, isSuccess: controlSuccess, error: controlError, isError: isControlError }] = useDeviceControlMutation();

  const handlePower = (powerStatus) => {
    let powerString = powerStatus ? "01" : "00";
    const bodyObject = {
      device: id,
      body: { powerStatus: powerString },
    };

    console.log("power status", powerStatus);
    sendPacket(bodyObject);
  };

  useEffect(() => {
    if (controlSuccess) {
      toast({
        title: "Command Sent To Device",
        status: "success",
        isClosable: true,
      });
    }
  }, [controlSuccess, controlData]);

  const {
    data: deviceData,
    isSuccess: deviceSuccess,
    error: deviceError,
    isError: isDeviceError,
  } = useFindDeviceByIdQuery(id, {
    pollingInterval: 2 * 60 * 1000,
    refetchOnMountOrArgChange: true,
  }); //calling every 2 mins

  return (
    <div className="h-full">
      {deviceData && (
        <div className="h-full">
          <div className="top w-full relative">
            <h1>
              AQI : <span className="font-bold"> {deviceData?.AQI}</span>
            </h1>
            <h1 className="font-bold text-xl">{deviceData.name}</h1>
            <p className="text-xs text-slate-500 mt-1">
              <p>{deviceData.deviceID}</p>
              <span className=""> {deviceData.roomObject.name} </span>
            </p>
            <div
              onClick={() => handlePower(deviceData.powerStatus)}
              className={`power absolute text-xl left-10 top-1/2 -translate-y-1/2 mr-3 ${
                deviceData.powerStatus ? "text-green-500" : "text-red-500"
              } rounded-full border-2 border-slate-200  w-12 h-12 flex justify-center items-center"}`}
            >
              <div className="flex justify-center items-center h-full">
                <AiOutlinePoweroff />
              </div>
            </div>
            <div className="networkStrength absolute right-10 top-1/2 -translate-y-1/2">
              <NetworkStrength signalStrength={deviceData.networkStrength} signalType={deviceData.networkType} height={3} />
            </div>
          </div>

          <div className="image w-4/5 mt-5 m-auto">
            <img src={hiveImage} alt="" />
          </div>

          {deviceData.sensorData && (
            <div className="">
              {/* <div className="health">
                <div
                  className={`healthContainer flex items-center bg-slate-200 w-4/5 mt-5 h-14 rounded-lg border ${
                    +deviceData.deviceFilterStatus?.hepaDaysRemaining < 20 ? "border-red-500" : "border-[#92C77F]"
                  }  border-l-4 m-auto`}
                >
                  <div className="icon ml-4 h-full flex items-center">
                    <img className="w-2/5" src={hepaImage} alt="hepa filter" />
                  </div>
                  <div className="text flex flex-col items-start">
                    <h3 className="font-bold">HEPA Filter</h3>
                    <p className="text-sm text-slate-600">{deviceData.deviceFilterStatus?.hepaDaysRemaining} Days till maintenance</p>
                  </div>
                </div>
                <div
                  className={`healthContainer flex items-center bg-slate-200 w-4/5 mt-2 h-14 rounded-lg border ${
                    +deviceData.deviceFilterStatus?.acfDaysRemaining < 20 ? "border-red-500" : "border-[#92C77F]"
                  }  border-l-4 m-auto`}
                >
                  <div className="icon ml-4 h-full flex items-center">
                    <img className="w-2/5" src={hepaImage} alt="hepa filter" />
                  </div>
                  <div className="text flex flex-col items-start">
                    <h3 className="font-bold">Activated Carbon Filter</h3>
                    <p className="text-sm text-slate-600">{deviceData.deviceFilterStatus?.acfDaysRemaining} Days till maintenance</p>
                  </div>
                </div>
              </div> */}
              <div className="info m-auto mt-5  w-4/5 grid grid-cols-2  gap-6">
                <div className="val">
                  <h2 className="values font-bold">{(+deviceData?.sensorData.co2).valueOf()} µg/m³</h2>
                  <p>CO2</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold">{(+deviceData?.sensorData.humidity).valueOf()}%</h2>
                  <p>Humidity</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold">{(+deviceData?.sensorData.p1).valueOf()} µg/m³</h2>
                  <p>PM 1.0</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold"> {(+deviceData?.sensorData.p10).valueOf()} µg/m³</h2>
                  <p>PM 10.0</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold"> {(+deviceData?.sensorData.p25).valueOf()} µg/m³</h2>
                  <p>PM 2.5</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold"> {(+deviceData?.sensorData.sound).valueOf()} db</h2>
                  <p>Noise</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold"> {(+deviceData?.sensorData.temperature).valueOf()}°C</h2>
                  <p>Temperature</p>
                </div>
                <div className="val">
                  <h2 className="values font-bold"> {(+deviceData?.sensorData.voc).valueOf()} ppb</h2>
                  <p>TVOC</p>
                </div>
              </div>
              <button
                onClick={() => navigate(`/settings/deviceControls/${deviceData._id}`)}
                className="bg-black  w-2/5 h-8 mt-10 text-lg mb-5 rounded-md text-white"
              >
                Control Device
              </button>
            </div>
          )}
          {!deviceData.sensorData && (
            <div className="info w-4/5 mt-10 m-auto">{"Please wait few minutes for the data to come in from the device."}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default DeviceInfo;
