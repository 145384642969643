import Modal from "../common/Modal";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useEditDeviceMutation } from "../../store/services/deviceApi";

const deviceSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Device Name is required"),
});

const EditDevice = () => {
  const { deviceName, deviceId } = useParams();

  const [editDevice, { data: deviceData, isSuccess: isDeviceSuccess, error: deviceError, isError: isDeviceError }] = useEditDeviceMutation();

  const toast = useToast();
  const navigate = useNavigate();

  let initialValues = {
    name: deviceName,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: deviceSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        device: deviceId,
        body: values,
      };
      editDevice(bodyObject);
      //   action.resetForm();
    },
  });

  useEffect(() => {
    if (isDeviceSuccess) {
      toast({
        title: "Device Edited Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceSuccess, deviceError]);

  useEffect(() => {
    if (isDeviceError) {
      console.log(deviceError);
      toast({
        title: `${deviceError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceError, deviceError]);

  return (
    <Modal>
      <h1 className="font-bold text-xl">Edit Device</h1>
      <form onSubmit={handleSubmit}>
        <div className="inpGrp text-left relative">
          <p className="text-left mt-5 mb-1 font-semibold">Edit Name</p>
          <input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder="Device Name"
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
        </div>
        <button type="submit" className="bg-black text-white w-3/5 h-8 mt-6 rounded-md">
          Edit Device
        </button>
      </form>
    </Modal>
  );
};

export default EditDevice;
