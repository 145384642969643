import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";

import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/features/authSlice";
import { useEffect } from "react";

import hiveImg from "../../assets/images/hiveImage.png";
import { TbSettings } from "react-icons/tb";
import { BiSolidEdit } from "react-icons/bi";

import { useDeviceListMutation } from "../../store/services/deviceApi";
import { useListRoomsMutation } from "../../store/services/roomsApi";

const Settings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deviceList, { data: deviceData }] = useDeviceListMutation();
  const [roomList, { data: roomData }] = useListRoomsMutation();

  useEffect(() => {
    deviceList();
    roomList();
  }, []);

  const logoutUser = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="text-left w-11/12 m-auto ">
      <h1 className="font-bold text-2xl mt-3 mb-5 ml-1 ">Settings</h1>

      <Accordion borderRadius={"3xl"} allowMultiple>
        <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          bgColor={"#F9F9F9"}
          border={"1px"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#F9F9F9" }}
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton borderRadius={"xl"} _hover={{ bgColor: "#F9F9F9" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                    Devices
                  </Box>
                  <AccordionIcon fontSize={"2xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className="bg-slate-400 h-px w-4/5 m-auto"></div>}
              <AccordionPanel pb={4} bgColor={"#F9F9F9"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                {deviceData &&
                  deviceData.map((device) => {
                    return (
                      <div className="h-16 relative mt-1">
                        <div className="name flex w-3/5 items-center">
                          <h1 className="font-bold text-sm mr-1">{device.name}</h1> |{" "}
                          <p className="text-xs text-slate-500 ml-1">{device.roomObject.name}</p>
                        </div>
                        <img className="w-1/2 mt-1" src={hiveImg} alt="" />
                        <div className="action absolute right-2 top-2 flex text-xl">
                          <TbSettings onClick={() => navigate(`/settings/deviceControls/${device._id}`)} className="mr-4 cursor-pointer" />
                          <BiSolidEdit onClick={() => navigate(`/settings/editDevice/${device.name}/${device._id}`)} />
                        </div>
                      </div>
                    );
                  })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem
          minHeight={"14"}
          borderRadius={"xl"}
          marginTop={"3"}
          bgColor={"#F9F9F9"}
          border={"1px"}
          borderColor={"rgba(0, 0, 0, 0.1)"}
          _hover={{ bgColor: "#F9F9F9" }}
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton borderRadius={"xl"} _hover={{ bgColor: "#F9F9F9" }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                    Rooms
                  </Box>
                  <AccordionIcon fontSize={"2xl"} />
                </AccordionButton>
              </h2>
              {isExpanded && <div className="bg-slate-400 h-px w-4/5 m-auto"></div>}
              <AccordionPanel pb={4} bgColor={"#F9F9F9"} borderRadius={"xl"} borderColor={"rgba(0, 0, 0, 0.1)"}>
                {roomData &&
                  roomData.map((room) => {
                    return (
                      <div className="h-12 relative mt-1">
                        <div className="name w-3/5 items-center">
                          <h1 className="font-bold text-sm mr-1">{room.name}</h1>
                          <p className="text-xs text-slate-500">
                            {room.area}sq. ft. x {room.height}ft.
                          </p>
                        </div>
                        <div className="action absolute right-2 top-2 flex text-xl">
                          <BiSolidEdit onClick={() => navigate(`/settings/editRooms/${room._id}`)} />
                        </div>
                      </div>
                    );
                  })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <div
        onClick={logoutUser}
        className="container mt-3 w-full px-5 flex items-center m-auto bg-[#F9F9F9] h-14 rounded-xl border border-opacity-10 font-bold"
      >
        Logout
      </div>
    </div>
  );
};

export default Settings;
