import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { useCreateDeviceMutation } from "../../store/services/deviceApi";
import { useEffect } from "react";

import { useToast } from "@chakra-ui/react";

import Modal from "../common/Modal";

const initialValues = {
  name: "",
  deviceId: "",
};

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Device Name is required"),
  deviceId: Yup.string().required("Device ID Is Required"),
});

const AddDevice = () => {
  const navigate = useNavigate();
  const [createDevice, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess }] = useCreateDeviceMutation();
  const toast = useToast();

  const { roomID } = useParams();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        room: roomID,
        body: values,
      };
      createDevice(bodyObject);
      // action.resetForm();
    },
  });

  useEffect(() => {
    if (isDeviceSuccess) {
      toast({
        title: "Device Added Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/devices/${deviceData._id}`);
    }
  }, [isDeviceSuccess, deviceError]);

  useEffect(() => {
    if (isDeviceError) {
      console.log(deviceError);
      toast({
        title: `${deviceError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isDeviceError, deviceError]);

  return (
    <Modal>
      <h1 className="font-bold text-2xl">Add Device</h1>

      <form onSubmit={handleSubmit} className="mt-5" action="">
        <div className="inpGrp text-left relative">
          <label className=" font-semibold">Name Of The Device</label>
          <input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder=""
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
        </div>

        <div className="inpGrp text-left mt-4 relative">
          <label className=" font-semibold">Device ID</label>
          <input
            name="deviceId"
            id="deviceId"
            value={values.deviceId}
            onChange={handleChange}
            type="text"
            placeholder=""
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full relative"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.deviceId && touched.deviceId ? errors.deviceId : ""}</span>
        </div>
        <button type="submit" className="bg-black text-white w-3/5 h-8 mt-6 rounded-md">
          Add Device
        </button>
      </form>
    </Modal>
  );
};

export default AddDevice;
