import { useEffect, useState } from "react";
import homeIcon from "../assets/navIcons/home.svg";
import settingIcon from "../assets/navIcons/setting.svg";
import supportIcon from "../assets/navIcons/support.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./nav.css";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let [navActive, setNav] = useState({
    nav1: true,
    nav2: false,
    nav3: false,
  });
  useEffect(() => {
    // location.pathname.includes("dashboard")
    if (location.pathname.includes("dashboard")) {
      setNav({ nav1: true, nav2: false, nav3: false });
    }
    if (location.pathname.includes("support")) {
      setNav({ nav1: false, nav2: true, nav3: false });
    }
    if (location.pathname.includes("settings")) {
      setNav({ nav1: false, nav2: false, nav3: true });
    }
  }, [window.location.href]);

  return (
    <div className="bottom-navbar w-screen z-20 h-14 rounded-t-xl flex items-center shadow-xl overflow-hidden shadow-black border fixed bottom-0">
      <div className="navigations flex w-full items-center h-full bg-white">
        <div onClick={() => navigate("/dashboard/rooms")} className={`w-1/2 h-full flex items-center justify-center relative`}>
          <img src={homeIcon} alt="home icon" /> {navActive.nav1 && <div className=" h-1 w-4/5 absolute bottom-0 bg-black rounded-lg" />}
        </div>
        {/* <div onClick={() => navigate("/support")} className={`w-1/3 h-full flex items-center justify-center relative`}>
          <img src={supportIcon} alt="support icon" /> {navActive.nav2 && <div className=" h-1 w-4/5 absolute bottom-0 bg-black rounded-lg" />}
        </div> */}
        <div onClick={() => navigate("/settings")} className={`w-1/2 h-full flex items-center justify-center relative`}>
          <img src={settingIcon} alt="setting icon" /> {navActive.nav3 && <div className=" h-1 w-4/5 absolute bottom-0 bg-black rounded-lg" />}
        </div>
      </div>
    </div>
  );
};
export default NavBar;
