import praanImg from "../../assets/images/praanwt.svg"

function DesktopPage() {
  return (
        <div className="h-screen w-screen bg-slate-800 text-white flex justify-center items-center">
          <div className="">
            <p className='mb-5 w-full flex justify-center'><img className='w-1/4' src={praanImg} alt="" /></p>
            <h1>Please Open This App On your Mobile Browser and Install it.<br /> <br /> below are installation instructions... 👇</h1>
            <div className="instructions flex justify-around mt-20">
               
                <div className="android mr-5">  
                  <h3 className='underline'>For Android</h3>
                  <ol className='text-left list-decimal'>
                    <li>On your Android device, open Chrome.</li>
                      <li> Open This Link.</li>
                      <li> Tap Install.</li>
                      <li> Follow the on-screen instructions.</li>
                  </ol>
                </div>

                <div className="IOS ml-5">
                  <h3 className='underline'>For IOS</h3>
                  <ol className='text-left list-decimal'>
                    <li>On your iPhone, open Chrome.</li>
                      <li> Open This Link.</li>
                      <li> At the top right of the address bar, tap Share </li>
                      <li> Find and tap Add to Home Screen.</li>
                      <li> Confirm or edit the website details and tap Add.</li>
                  </ol>
                </div>
            </div>
          </div>
        </div>
  );
}

export default DesktopPage;
