import Modal from "../common/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useFindRoomByIDQuery, useEditRoomMutation } from "../../store/services/roomsApi";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";

const roomsSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is too short!").max(50, "Name is too long!").required("Name is required"),
  area: Yup.number().required("Area Is Required"),
  height: Yup.number().required("Height Is Required"),
});

const EditRooms = () => {
  const { roomId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  let initialValues = {
    name: "",
    area: 0,
    height: 0,
  };

  const {
    data: roomData,
    isSuccess: roomSuccess,
    error: roomError,
    isError: isRoomError,
  } = useFindRoomByIDQuery(roomId, { refetchOnMountOrArgChange: true });

  const [editRoom, { data: editData, isSuccess: editSuccess, error: editError, isError: isEditError, isLoading: isLoading }] = useEditRoomMutation();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);
      const bodyObject = {
        room: roomId,
        body: values,
      };
      editRoom(bodyObject);
      //   action.resetForm();
    },
  });

  useEffect(() => {
    if (editSuccess) {
      toast({
        title: "Device Edited Successfully",
        status: "success",
        isClosable: true,
      });
      navigate(`/dashboard/rooms/${roomData._id}`);
    }
  }, [editData, editSuccess]);

  useEffect(() => {
    if (isEditError) {
      console.log(editError);
      toast({
        title: `${editError.message}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isEditError, editError]);

  useEffect(() => {
    if (roomSuccess) {
      setFieldValue("name", roomData.name);
      setFieldValue("area", roomData.area);
      setFieldValue("height", roomData.height);
    }
  }, [roomData, roomSuccess]);

  return (
    <Modal isLoading={isLoading}>
      <h1 className="font-bold text-xl">Edit Rooms</h1>

      <form onSubmit={handleSubmit}>
        <div className="inpGrp text-left relative">
          <p className="text-left mt-5 mb-1 font-semibold">Edit Name</p>
          <input
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder="Device Name"
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
        </div>
        <div className="inpGrp text-left relative">
          <p className="text-left mt-5 mb-1 font-semibold">Edit Area</p>
          <input
            name="area"
            id="area"
            value={values.area}
            onChange={handleChange}
            type="number"
            placeholder="Device Area"
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.area && touched.area ? errors.area : ""}</span>
        </div>
        <div className="inpGrp text-left relative">
          <p className="text-left mt-5 mb-1 font-semibold">Edit Height</p>
          <input
            name="height"
            id="height"
            value={values.height}
            onChange={handleChange}
            type="number"
            placeholder="Device Height"
            className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
          />
          <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.height && touched.height ? errors.height : ""}</span>
        </div>
        <button type="submit" className="bg-black text-white w-3/5 h-8 mt-6 rounded-md">
          Edit Device
        </button>
      </form>
    </Modal>
  );
};
export default EditRooms;
