import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const roomApi = createApi({
  reducerPath: "roomApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    listRooms: builder.mutation({
      query: () => {
        return {
          url: "/room",
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),

    findRoomByID: builder.query({
      query: (roomId) => {
        return {
          url: `/room/${roomId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createRoom: builder.mutation({
      query: (body) => {
        return {
          url: `/room`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    editRoom: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/room/${bodyObject.room}`,
          method: "put",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const { useListRoomsMutation, useFindRoomByIDQuery, useCreateRoomMutation, useEditRoomMutation } = roomApi;
