import { Box, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";

function CustomDropdown({ selectedValue, options, onChange }) {
  return (
    <Menu>
      <MenuButton border={"1px"} borderColor={"slategray"} h={"7"} background={"white"} as={Button} rightIcon={<IoIosArrowDown />}>
        {options.find((option) => option.value == selectedValue).label}
      </MenuButton>
      <MenuList transition={"none"} position={"absolute"} top={"10"} h={"36"} overflowY={"scroll"}>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => onChange(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default CustomDropdown;
