import praanLogo from "../assets/images/praan.svg";
import hive from "../assets/images/hive.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-screen h-14  z-20 flex items-center overflow-hidden  fixed top-0">
      <div className="flex w-full items-center justify-between h-full bg-white px-5">
        <img className="w-1/5" src={praanLogo} alt="praan logo" />
        <img className="w-4/12" src={hive} alt="Hive" />
      </div>
    </div>
  );
};
export default Header;
