import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { authApi } from "./services/authApi";
import { roomApi } from "./services/roomsApi";
import { userApi } from "./services/usersApi";
import { deviceApi } from "./services/deviceApi";

import authReducer from "./features/authSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { rtkQueryErrorLogger } from "../middlewares/error-handler";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

// First, combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [roomApi.reducerPath]: roomApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
});

// Then, wrap this combined reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer, 
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware, roomApi.middleware, deviceApi.middleware, userApi.middleware,rtkQueryErrorLogger),
});

export const persistor = persistStore(store);
