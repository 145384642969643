import { isRejectedWithValue } from "@reduxjs/toolkit";
import { store } from "../store/store";
import { authSlice } from "../store/features/authSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  console.log("actionsss.", action);

  // if (action.error && action.error.message == "Rejected") {
  //   // alert("offline");
  //   window.location.replace("/offline");
  // }

  if (isRejectedWithValue(action)) {
    if (action.payload?.message === "jwt expired" || action.payload?.message === "Invalid Token") {
      console.log("change state here");
      store.dispatch(authSlice.actions.logout());
    }
    console.warn("We got a rejected action!");
    //   toast.warn({ title: 'Async error!', message: action.error.data.message })
  }

  return next(action);
};
